import { useQuery } from '@tanstack/react-query';
import { remixApi } from '@tectonic/api-client';
import {
  queryKeys,
  useFragmentValue,
  usePageFragment,
} from '@tectonic/elemason';
import { isEmpty } from 'lodash-es';

import type { ElemasonOrdersWidget, PaginatedOrders } from '@tectonic/types';

const getOrders = async (): Promise<PaginatedOrders | any> => {
  const response = await remixApi.getOrders({ provider: 'baldur' });
  if (isEmpty(response.data) || response.error) {
    throw response.error;
  }
  return response.data;
};

const useOrders = (widget: ElemasonOrdersWidget) => {
  const wData = widget.data!;

  const {
    data: response,
    isLoading,
    isError,
  } = useQuery({
    queryFn: async () => getOrders(),
    queryKey: [queryKeys.orders()],
  });

  const fragment = usePageFragment(wData.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const fragmentData = fragmentValue({
    orders: response?.orders ?? [],
    isError,
    isLoading: isLoading,
  });

  return { fragmentData, fragment };
};

export { useOrders };
