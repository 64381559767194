import { Cell, ElemasonFragmentProvider } from '@tectonic/elemason';
import { useAuthProfileUpdate } from './hooks';

import type { FC } from 'react';
import type { AuthProfileUpdateWidgetProps } from './AuthProfileUpdate.types';

const AuthProfileUpdateWidget: FC<AuthProfileUpdateWidgetProps> = ({
  widget,
}) => {
  const { fragment, fragmentData } = useAuthProfileUpdate(widget);

  return (
    <ElemasonFragmentProvider value={fragmentData}>
      {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
    </ElemasonFragmentProvider>
  );
};

AuthProfileUpdateWidget.displayName = 'AuthProfileUpdateWidget';

export { AuthProfileUpdateWidget };
