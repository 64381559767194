import { useMutation } from '@tanstack/react-query';
import {
  useActionDispatch,
  useElemasonContext,
  useFragmentValue,
  usePageFragment,
  useToast,
} from '@tectonic/elemason';
import { getErrorMessage } from '@tectonic/errors';
import { updateUser } from '@tectonic/remix-client-network';
import { delay } from '@tectonic/utils';
import { isEmpty, omitBy } from 'lodash-es';

import type { ProfileEditFormValue } from '@tectonic/elemason';
import type { UserUpdateRequestPayload } from '@tectonic/types';
import type { ElemasonAuthProfileUpdateWidget } from './AuthProfileUpdate.types';

const updateProfileMutationFn = async (
  payload: Partial<UserUpdateRequestPayload>
) => {
  const response = await updateUser(payload);

  if (response.error) {
    throw response.error;
  }

  return response.data;
};

const useAuthProfileUpdate = (widget: ElemasonAuthProfileUpdateWidget) => {
  const wData = widget.data!;
  const wActions = widget.actions;
  const { showToast } = useToast();
  const dispatch = useActionDispatch();
  const { currentUser } = useElemasonContext();

  const {
    isPending: isProfileUpdating,
    mutateAsync: updateProfile,
    isError: hasProfileUpdateError,
  } = useMutation({
    mutationFn: updateProfileMutationFn,
    mutationKey: [],
  });

  const onProfileUpdate = async (
    value: Pick<ProfileEditFormValue, 'firstName' | 'lastName'>
  ) => {
    try {
      // TODO: fix this when backend supports partial update.
      const payload = omitBy({ phone: currentUser.phone, ...value }, isEmpty);
      await updateProfile(payload);
      showToast({ title: wData.messages.profileUpdateSuccess });
      if (wActions?.onSuccessfulUpdate) {
        wActions.onSuccessfulUpdate.forEach((action) => dispatch(action));
      } else {
        // We want user to see the toast. For now we are using delay. In future,
        // we might use flash message or a better solution for this.
        await delay(1000);
        // Successful login. Let's reload the page.
        globalThis.location.reload();
      }
    } catch (error) {
      showToast({ title: getErrorMessage(error, wData.errorMessages) });
    }
  };

  const fragment = usePageFragment(wData.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const fragmentData = fragmentValue({
    isProfileUpdating,
    onProfileUpdate,
    hasProfileUpdateError,
  });
  return { fragment, fragmentData };
};

export { useAuthProfileUpdate };
