import { useMutation } from '@tanstack/react-query';
import { toPhoneText, useAuthStore, useToast } from '@tectonic/elemason';
import { getErrorMessage } from '@tectonic/errors';
import { Logger } from '@tectonic/logger';
import {
  checkUserExists,
  requestOtp,
  requestOtpForSignUp,
} from '@tectonic/remix-client-network';
import { ElemasonAuthMode, ElemasonAuthStep } from '@tectonic/types';
import { useForm } from '@tectonic/uikit';
import { isBlank } from '@tectonic/utils';
import { isEmpty } from 'lodash-es';
import { useMemo, useState } from 'react';

import type { Nil, UserExistsResponse } from '@tectonic/types';
import type { FormSubmitHandler } from '@tectonic/uikit';
import type {
  AuthPhoneNoOtpSignInFormValue,
  ElemasonAuthPhoneNoOtpSignInFormWidget,
} from './AuthPhoneNoOtpSignInForm.types';

const getUserExistence = async ({
  phone,
  email,
}: {
  phone?: string;
  email?: string;
}): Promise<UserExistsResponse> => {
  const response = await checkUserExists({ phone, email });
  if (response.error || isEmpty(response.data)) {
    throw response.error;
  }

  return response.data;
};

const useDefaultValues = (
  data: NonNullable<ElemasonAuthPhoneNoOtpSignInFormWidget['data']>
): Partial<AuthPhoneNoOtpSignInFormValue> =>
  useMemo(() => {
    const country = data.defaultCountry;
    return { phoneNumber: { country, phone: '' } };
  }, [data]);

const usePhoneOtpSignInForm = (
  widget: ElemasonAuthPhoneNoOtpSignInFormWidget
) => {
  const { setStep } = useAuthStore((state) => ({
    setStep: state.setStep,
  }));
  const wData = widget.data!;
  const { showToast } = useToast();
  const defaultValues = useDefaultValues(wData);

  const {
    control,
    formState,
    setError,
    getValues: getFormValues,
    reset,
  } = useForm<AuthPhoneNoOtpSignInFormValue>({
    defaultValues,
    mode: 'onChange',
  });

  // Initially we don't know whether for a phone number we need to trigger
  // sign up or sign in. This flag will be set to true if sign up is required.
  const [isSignUpRequired, setIsSignUpRequired] = useState<Nil<boolean>>();

  const onPreviewEdit = () => {
    setIsSignUpRequired(null);
    setStep(ElemasonAuthStep.PHONE_NO_OTP_SIGN_IN, {
      mode: ElemasonAuthMode.SIGN_IN,
    });
    reset();
  };

  const triggerSignUpFlow = async (value: AuthPhoneNoOtpSignInFormValue) => {
    // We already have the phone number of the user. Let's validate email.
    const { exists: isExisting } = await getUserExistence({
      email: value.email,
    });
    if (isExisting) {
      setError('email', {
        type: 'custom',
        message: wData.messages.emailAlreadyExists,
      });
      return;
    }
    const phone = toPhoneText(value.phoneNumber)!;
    const otpResponse = await requestOtpForSignUp(phone);
    if (otpResponse.error) {
      throw otpResponse.error;
    }
    setStep(ElemasonAuthStep.PHONE_NO_OTP_CONFIRM, {
      email: value.email,
      phoneNumber: value.phoneNumber,
      mode: ElemasonAuthMode.SIGN_UP,
    });
  };

  const triggerSignInFlow = async (value: AuthPhoneNoOtpSignInFormValue) => {
    const { phoneNumber } = value;
    const phone = toPhoneText(phoneNumber)!;
    const { signupRequired } = await getUserExistence({ phone });
    if (signupRequired) {
      setIsSignUpRequired(true);
      setStep(ElemasonAuthStep.PHONE_NO_OTP_SIGN_IN, {
        mode: ElemasonAuthMode.SIGN_UP,
      });
      return;
    }
    const otpResponse = await requestOtp({ phone });
    if (otpResponse.error) {
      throw otpResponse.error;
    }
    showToast({ title: wData.messages.otpSendSuccess });
    setStep(ElemasonAuthStep.PHONE_NO_OTP_CONFIRM, {
      phoneNumber,
      email: null,
    });
  };

  const mutationFn = async (value: AuthPhoneNoOtpSignInFormValue) => {
    try {
      if (isSignUpRequired) {
        await triggerSignUpFlow(value);
        return;
      }
      await triggerSignInFlow(value);
    } catch (error) {
      Logger.error('Auth Phone No Otp Sign In Form', error);
      showToast({ title: getErrorMessage(error, wData.errorMessages) });
    }
  };

  const { isPending: isLoading, mutate: onSubmit } = useMutation({
    mutationFn,
    mutationKey: [],
  });

  const handleSubmit: FormSubmitHandler<AuthPhoneNoOtpSignInFormValue> = (
    formData
  ) => {
    const value = formData.data;
    if (isBlank(value.phoneNumber.phone)) {
      setError('phoneNumber', {
        type: 'custom',
        message: wData.phoneInput.textField.required!,
      });
      return;
    }
    if (isSignUpRequired && isBlank(value.email)) {
      setError('email', {
        type: 'custom',
        message: wData.emailInput.textField.required!,
      });
      return;
    }
    onSubmit(value);
  };

  return {
    isLoading,
    handleSubmit,
    isSignUpRequired,
    onPreviewEdit,
    control,
    formState,
    getFormValues,
  };
};

export { usePhoneOtpSignInForm };
