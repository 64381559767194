import { Cell, ElemasonFragmentProvider } from '@tectonic/elemason';
import { useOrders } from './hooks';

import type { FC } from 'react';
import type { OrdersWidgetProps } from './Orders.types';

const OrdersWidget: FC<OrdersWidgetProps> = ({ widget }) => {
  const { fragment, fragmentData } = useOrders(widget);

  return (
    <ElemasonFragmentProvider value={fragmentData}>
      {fragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
    </ElemasonFragmentProvider>
  );
};

OrdersWidget.displayName = 'OrdersWidget';

export { OrdersWidget };
