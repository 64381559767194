import type { FC } from 'react';
import { useExperiment } from '../hooks';
import { EddWidget } from './Edd';
import { EddWidgetProps } from './Edd.types';

const EddWidgetWrapper: FC<EddWidgetProps> = ({ widget }) => {
  const { shouldRender } = useExperiment({
    ignoreExperiment: widget.data?.isFbv,
    expVariant: widget.data?.expVariant,
  });

  if (!shouldRender) {
    return null;
  }

  return <EddWidget widget={widget} />;
};

EddWidgetWrapper.displayName = 'EddWidgetWrapper';

export { EddWidgetWrapper as EddWidget };
