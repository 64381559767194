import { Button, TextField, useHaloScript } from '@tectonic/elemason';
import { Text, useStyleConfig } from '@tectonic/elemason-components';
import {
  Controller,
  Form,
  FormErrorMessage,
  InputField2,
  Typography,
} from '@tectonic/uikit';
import clsx from 'clsx';
import { PhoneNoPreview } from './PhoneNoPreview';
import { usePhoneOtpSignInForm } from './hooks';

import type { FC } from 'react';
import type { AuthPhoneNoOtpSignInFormWidgetProps } from './AuthPhoneNoOtpSignInForm.types';

const AuthPhoneNoOtpSignInFormWidget: FC<
  AuthPhoneNoOtpSignInFormWidgetProps
> = ({ widget }) => {
  const config = widget.config;
  const wData = widget.data!;
  const wAction = widget.actions!;
  const containerStyleConfig = useStyleConfig(config?.container ?? {});
  const phContainerStyleConfig = useStyleConfig(
    config?.phoneInput?.container ?? {}
  );

  const submitCtaPlaceholders = useHaloScript<Record<string, string>>(
    wData.submitCta.button.placeholders
  );

  const submitCtaContainerStyleConfig = useStyleConfig(
    config?.submitCta?.container ?? {}
  );

  const {
    isLoading,
    isSignUpRequired,
    handleSubmit,
    onPreviewEdit,
    control,
    formState,
    getFormValues,
  } = usePhoneOtpSignInForm(widget);

  const { errors } = formState;

  return (
    <Form control={control} onSubmit={handleSubmit}>
      <div
        style={containerStyleConfig.style}
        className={clsx('flex flex-col', containerStyleConfig.className)}
      >
        {!isSignUpRequired ? (
          <div className="flex flex-col gap-2">
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field }) => (
                // TODO: User PhoneNumberInput component when it is configurable.
                // Current one is not fully customizable.
                <div
                  className={clsx('flex', phContainerStyleConfig.className)}
                  style={phContainerStyleConfig.style}
                >
                  <Text data={wData.defaultCountry.stdCodes[0]} />
                  <InputField2
                    className="grow"
                    type="number"
                    size="md"
                    value={field.value.phone}
                    onChange={(event) => {
                      field.onChange({
                        ...field.value,
                        phone: event.target.value,
                      });
                    }}
                    placeholder={wData.phoneInput.textField.placeholder}
                  />
                </div>
              )}
            />
            <FormErrorMessage
              name="phoneNumber"
              errors={errors}
              as={Typography}
              className="text-danger-600"
              variant="body4"
            />
          </div>
        ) : (
          <>
            <PhoneNoPreview
              phoneNumber={getFormValues('phoneNumber')}
              onEdit={onPreviewEdit}
              data={wData.phoneNoPreview}
              config={config?.phoneNoPreview}
            />
            <div className={clsx('flex flex-col gap-2')}>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    size="md"
                    data={wData.emailInput.textField}
                    config={config?.emailInput?.textField}
                    value={field.value}
                    type="email"
                    disabled={isLoading}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                  />
                )}
              />
              <FormErrorMessage
                name="email"
                errors={errors}
                as={Typography}
                className="text-danger-600"
                variant="body4"
              />
            </div>
          </>
        )}
        <div
          style={submitCtaContainerStyleConfig.style}
          className={clsx('flex', submitCtaContainerStyleConfig.className)}
        >
          <Button
            isLoading={isLoading}
            className="grow"
            type="submit"
            data={wData.submitCta.button}
            config={config?.submitCta?.button}
            placeholders={submitCtaPlaceholders}
          />
        </div>
      </div>
    </Form>
  );
};

AuthPhoneNoOtpSignInFormWidget.displayName = 'AuthPhoneNoOtpSignInFormWidget';

export { AuthPhoneNoOtpSignInFormWidget };
