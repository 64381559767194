import {
  useFragmentValue,
  useHaloScript,
  usePageFragment,
} from '@tectonic/elemason';
import { Serviceability } from '@tectonic/types';
import { addDays, format, isSameDay } from 'date-fns';
import { has, isNil } from 'lodash-es';
import { useMemo } from 'react';
import {
  CutoffThreshold,
  ElemasonEddWidget,
  ProductVariantServiceability,
} from './Edd.types';

const DEFAULT_EDD_DAYS = 4;
const DEFAULT_FORMAT = 'iii, do MMM';

const adjustDate = (date: Date, cutoff?: CutoffThreshold) => {
  if (!cutoff) {
    return date;
  }
  const nDate = new Date(date);
  const { cutoff: timestamp, cutoffUnit: timestampUnit, delta = 1 } = cutoff;
  let cutoffTimeInMilliseconds = 0;

  // Convert the cutoff timestamp into milliseconds based on the provided unit
  switch (timestampUnit) {
    case 'seconds':
      cutoffTimeInMilliseconds = timestamp * 1000;
      break;
    case 'minutes':
      cutoffTimeInMilliseconds = timestamp * 60 * 1000;
      break;
    case 'hours':
      cutoffTimeInMilliseconds = timestamp * 60 * 60 * 1000;
      break;
    default:
      break;
  }
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  // Calculate the exact cutoff date and time by adding the cutoff time to the start of the day
  const cutoffDateTime = new Date(
    startOfDay.getTime() + cutoffTimeInMilliseconds
  );

  // If the current date time is past the cutoff, add the delta in days to the new date
  if (nDate > cutoffDateTime) {
    nDate.setDate(nDate.getDate() + (delta ?? 1));
  }

  return nDate;
};

/**
 * Logic to derive EDD from variant serviceability.
 * 1. Use deliversIn.maxSeconds to calculate the time it take to deliver.
 * 2. Check the cutoff time. If cutoff time has passed add 1 day.
 * 3. If the data is not present use 4 days.
 */
const getEddFromVariantServiceability = ({
  serviceability,
}: {
  serviceability: ProductVariantServiceability;
}): Date => {
  const deliversIn = serviceability.hits[0].deliversIn;
  const offsetDays = Math.floor(deliversIn.maxSeconds / (60 * 60 * 24));
  return addDays(new Date(), offsetDays || 0);
};

/**
 * Logic to derive EDD from serviceability:
 * Just use max duration from additionalInfo.edd. Backend takes care of the rest.
 *
 */
const getEddFromServiceability = ({
  serviceability,
}: {
  serviceability: Serviceability;
}): Date => {
  const edd = serviceability.additionalInfo.edd!;
  const offsetDays = Math.floor(+edd.max.duration / (60 * 60 * 24));
  return addDays(new Date(), offsetDays || 0);
};

const hasServiceability = (
  serviceability?: ProductVariantServiceability | Serviceability
): boolean => {
  if (!serviceability) {
    return false;
  }

  if (!isNil((serviceability as ProductVariantServiceability).hits?.length)) {
    return true;
  }

  if (
    !isNil(
      (serviceability as Serviceability).additionalInfo?.edd?.max?.duration
    )
  ) {
    return true;
  }

  return false;
};

const getDefaultEdd = (): Date => {
  // Get it by 9:00PM on Thu, 28th Nov
  const today = new Date();
  return addDays(today, DEFAULT_EDD_DAYS);
};

const getEdd = ({
  serviceability,
}: {
  serviceability?: ProductVariantServiceability | Serviceability;
}): Date => {
  if (!hasServiceability(serviceability)) {
    return getDefaultEdd();
  }
  const isVariantServiceability = has(serviceability, 'hits');

  return isVariantServiceability
    ? getEddFromVariantServiceability({
        serviceability: serviceability as ProductVariantServiceability,
      })
    : getEddFromServiceability({
        serviceability: serviceability as Serviceability,
      });
};

const isExpressDelivery = (edd: Date): boolean => {
  // if the edd is within 48 hours, show express delivery
  return edd.getTime() - new Date().getTime() < 48 * 60 * 60 * 1000;
};

const isNDaysFromToday = (n: number, givenDate: Date) => {
  const today = new Date();
  const targetDate = addDays(today, n);
  return isSameDay(givenDate, targetDate);
};

const formatEdd = ({ edd }: { edd: Date }): string => {
  if (isNDaysFromToday(0, edd)) {
    return `<span className="font-semibold">Delivery Today</span>`;
  }

  if (isNDaysFromToday(1, edd)) {
    return `<span className="font-semibold">Delivery Tomorrow</span>`;
  }

  try {
    const result = format(edd, DEFAULT_FORMAT);
    return `Get it by <span className="font-semibold">${result}</span>`;
  } catch (err) {}

  return '';
};

const useEddWidget = (widget: ElemasonEddWidget) => {
  const wData = widget.data!;

  const fragment = usePageFragment(wData.fragment);
  const fragmentValue = useFragmentValue(fragment);
  const serviceability = useHaloScript<
    ProductVariantServiceability | Serviceability
  >(wData.serviceability);
  const cutoff = useHaloScript<CutoffThreshold>(wData.cutoffThreshold);
  const isFbv = useHaloScript<boolean>(wData.isFbv);

  const value = useMemo(() => {
    const result = getEdd({ serviceability });
    const edd = cutoff ? adjustDate(result, cutoff) : result;
    return fragmentValue({
      isFbv: !!isFbv,
      edd: formatEdd({ edd }),
      isExpressDelivery: isExpressDelivery(result),
    });
  }, [fragmentValue, serviceability, isFbv]);

  return { value, fragment };
};

export { useEddWidget };
