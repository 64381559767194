import { useMutation } from '@tanstack/react-query';
import {
  AnalyticsCartEventNames,
  DEFAULT_PAYLOAD_ENRICHMENT_TIMEOUT,
  getAnalyticsEventAttributes,
  trackCartEvent,
} from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import {
  Cell,
  ElemasonFragmentProvider,
  enrichAnalyticsPayloadWithWidgetData,
  useElemasonContext,
  useFragmentValue,
  usePageFragment,
} from '@tectonic/elemason';
import { Logger } from '@tectonic/logger';
import { useEffect } from 'react';

import type { AnalyticsCartEventPayload } from '@tectonic/analytics';
import type {
  Cart,
  CartAnalyticsAttribute,
  CartFinalizePayload,
} from '@tectonic/types';
import type { FC } from 'react';
import type { CheckoutRedirectWidgetProps } from './CheckoutRedirect.types';

const CheckoutRedirectWidget: FC<CheckoutRedirectWidgetProps> = ({
  widget,
}) => {
  const { currentUser } = useElemasonContext();
  const isAnonymous = !!currentUser?.isAnonymous;
  const fragment = usePageFragment(widget.data?.fragment);
  const fragmentValue = useFragmentValue(fragment);

  const {
    mutate,
    isPending: isLoading,
    isError: hasError,
  } = useMutation({
    mutationFn: async () => {
      let attributes: CartAnalyticsAttribute[] = [];
      try {
        attributes = await getAnalyticsEventAttributes(
          DEFAULT_PAYLOAD_ENRICHMENT_TIMEOUT
        );
      } catch (error) {
        Logger.error('Error in fetching cart attributes ', error);
      }

      const thirdPartyEnrichment: CartFinalizePayload['thirdPartyEnrichment'] =
        {
          provider: 'RAZORPAY_MAGIC',
          providerPayload: { url: window.location.href },
        };
      // Logger.info("Finalise client attributes", attributes)
      return remixApi.finalizeCart({ attributes, thirdPartyEnrichment });
    },
    onSuccess: (nCart: Cart) => {
      trackCartEvent(
        AnalyticsCartEventNames.CHECKOUT_BEGIN,
        enrichAnalyticsPayloadWithWidgetData<AnalyticsCartEventPayload>(
          { cart: nCart, isGuest: isAnonymous, isExpress: false },
          widget
        ),
        true
      );

      // replacing url with checkout url so that its not part of the history.
      window.location.replace(nCart.checkoutUrl);
    },
    onError: (error) => {
      Logger.error('Unable to begin checkout', error);
    },
  });

  useEffect(() => {
    mutate();
  }, [mutate]);

  return (
    <ElemasonFragmentProvider
      value={fragmentValue({
        isLoading,
        hasError,
        onRetry: () => {
          mutate();
        },
      })}
    >
      {fragment?.cells.map((cell, index) => (
        <Cell key={cell.id ?? index} cell={cell} />
      ))}
    </ElemasonFragmentProvider>
  );
};

CheckoutRedirectWidget.displayName = 'CheckoutRedirectWidget';

export { CheckoutRedirectWidget };
