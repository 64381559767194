import { useEffect } from 'react';
import { Logger } from '@tectonic/logger';

const getFirebaseConfig = (env: Record<string, string>) => {
  const config = {
    apiKey: env.FIREBASE_API_KEY,
    authDomain: env.FIREBASE_AUTH_DOMAIN,
    projectId: env.FIREBASE_PROJECT_ID,
    storageBucket: env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
    appId: env.FIREBASE_APP_ID,
    measurementId: env.FIREBASE_MEASUREMENT_ID,
  };
  return config;
};

const setupFirebase = async (env: Record<string, string>) => {
  try {
    const config = getFirebaseConfig(env);
    const module = await import('../firebase/app');
    const { initializeApp } = module;
    initializeApp(config);
  } catch (error) {
    Logger.error('Firebase module could not be loaded');
    Logger.error(error);
  }
};

const useFirebaseEffect = (context: unknown) => {
  const { env } = context as { env: Record<string, string> };
  useEffect(() => {
    setupFirebase(env);
  }, [env]);
};

export default useFirebaseEffect;
