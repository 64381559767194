import { useNavigate } from '@remix-run/react';
import { ElemasonProvider } from '@tectonic/elemason';
import { Logger } from '@tectonic/logger';
import { NavigationActionType } from '@tectonic/types';
import { populateWithEncode, toUrlSearchParams } from '@tectonic/utils';
import { omit } from 'lodash-es';
import { useMemo } from 'react';
import { useParams } from './hooks';

import type {
  ElemasonWidget,
  NavigationActionPayload,
  User,
} from '@tectonic/types';
import type { ComponentProps, ComponentType, FC } from 'react';

type Route = {
  path: string;
};

type Routes = Record<string, Route>;

interface ElemasonRemixProviderProps
  extends Omit<ComponentProps<typeof ElemasonProvider>, 'value'> {
  value: {
    user: User;
    routes: Routes;
    env: Record<string, any>;
    widgets?: Record<
      string,
      ComponentType<{ widget: ElemasonWidget<unknown, unknown, unknown> }>
    >;
    experiments?: {
      athenaVariantInfo?: { expVariant?: string; defaultVariant?: string };
      athenaRootVariantInfo?: { expVariant?: string; defaultVariant?: string };
    };
  };
}

const withSearchParams = (
  pathname: string,
  search?: Record<string, unknown>,
  omitParams?: string[]
) =>
  `${pathname}${
    search ? `?${toUrlSearchParams(omit(search, omitParams ?? []))}` : ''
  }`;

const getHref = (routes: Routes, payload: NavigationActionPayload) => {
  try {
    switch (payload.type) {
      case NavigationActionType.PDP: {
        const path = populateWithEncode(routes.pdp.path, payload.route);
        return withSearchParams(path, payload.search, payload.omitParams);
      }

      case NavigationActionType.PRODUCT_REVIEWS: {
        const path = populateWithEncode(
          routes['product-reviews'].path,
          payload.route
        );
        return withSearchParams(path, payload.search, payload.omitParams);
      }

      case NavigationActionType.COLLECTION: {
        const path = populateWithEncode(routes.collection.path, payload.route);
        return withSearchParams(path, payload.search, payload.omitParams);
      }

      case NavigationActionType.ORDER: {
        const path = populateWithEncode(routes.order.path, payload.route);
        return withSearchParams(path, payload.search, payload.omitParams);
      }

      case NavigationActionType.ORDER_ITEM: {
        const path = populateWithEncode(
          routes['order-details'].path,
          payload.route
        );
        return withSearchParams(path, payload.search, payload.omitParams);
      }

      case NavigationActionType.PATH: {
        const path = populateWithEncode(
          routes[payload.slug].path,
          payload.route ?? {}
        );
        return withSearchParams(path, payload.search, payload.omitParams);
      }

      default:
        throw new Error('Invalid navigation action');
    }
  } catch (e) {
    Logger.error(
      `Error while generating href ${JSON.stringify(
        payload
      )}. Check if page exists`
    );
    return '';
  }
};

const getNavigationRegistry = (routes: Routes) => ({
  getHref(payload: NavigationActionPayload) {
    return getHref(routes, payload);
  },
});

// we need to so some sanitization on values so that accessor can have proper
// conditions. eg. user returned from backend can not have phone key.
const useSanitizedUser = (user: User) =>
  useMemo(() => ({ ...user, phone: user?.phone ?? null }), []);

const ElemasonRemixProvider: FC<ElemasonRemixProviderProps> = ({
  value,
  children,
}) => {
  const { routeParams, searchParams, pathname } = useParams();
  const navigate = useNavigate();

  const sanitizedUser = useSanitizedUser(value.user);
  const navigationRegistry = useMemo(
    () => getNavigationRegistry(value.routes),
    [value.routes]
  );

  return (
    <ElemasonProvider
      value={{
        navigate,
        pathname,
        routeParams,
        searchParams,
        env: value.env,
        navigationRegistry,
        widgets: value.widgets,
        currentUser: sanitizedUser,
        experiments: value.experiments,
      }}
    >
      {children}
    </ElemasonProvider>
  );
};

ElemasonRemixProvider.displayName = 'ElemasonRemixProvider';

export { ElemasonRemixProvider };
export type { Routes };
