const razorpayMagicShopifyCheckoutConfig = {
  status: 'live',
  isShippingEngineEnabled: true,
  isEmailMandatory: true,
  isLoginMandatory: true,
  permalinksFlow: true,
  merchantKey: 'rzp_live_iutJcMjxftLyxl',
  merchantName: 'VAAREE',
  merchantThemeColor: '#FFC845',
  // New sdk config below
  "isForceV2": true,
  "checkoutSrc": "https://betacdn.np.razorpay.in/checkout/builds/commit-builds/c67b619ce1054e28353b6b9b4ade4580eaa09f56/v1/magic-checkout.js",
  "isCleverTapEnabled": true,
};

export { razorpayMagicShopifyCheckoutConfig };
