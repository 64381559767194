import { Cell, ElemasonFragmentProvider } from '@tectonic/elemason';
import { EddWidgetProps } from './Edd.types';

import { FC, memo } from 'react';
import { useEddWidget } from './hooks';

const EddWidget: FC<EddWidgetProps> = ({ widget }) => {
  const { fragment, value } = useEddWidget(widget);

  return fragment ? (
    <ElemasonFragmentProvider value={value}>
      {fragment.cells.map((cell, index) => (
        <Cell key={cell.id ?? index} cell={cell} />
      ))}
    </ElemasonFragmentProvider>
  ) : null;
};

EddWidget.displayName = 'EddWidget';

const MemoizedEddWidget = memo(EddWidget);

export { MemoizedEddWidget as EddWidget };
