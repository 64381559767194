const colors = {
  black: '#000',
  white: '#FFF',
  star: '#EAB308',
  primary: {
    50: '#FFF9EC',
    200: '#FBEECA',
    300: '#FFE3A2',
    600: '#FFC845',
    700: '#E5B43E',
    800: '#806423',
    950: 'yellow', // TODO: Add value from theme.
  },
  secondary: {
    50: '#EFF0E9',
    200: '#E6EBDC',
    300: '#CDD7B9',
    600: '#839A51',
    700: '#0E8389',
    800: '#667E32',
    950: 'yellow', // TODO: Add value from theme.
  },
  accent: {
    50: '#E0F0F9',
    200: '#B3DAF1',
    300: '#93BFDA',
    600: '#7BA0B6',
    700: '#4E819F',
    800: '#31617D',
  },

  success: {
    50: '#E2EEE3',
    200: '#ADCBAE',
    300: '#89B38B',
    600: '#428445',
    700: '#356A37',
    800: '#284F29',
    950: 'yellow', // TODO: Add value from theme.
  },
  danger: {
    50: '#FDE6E8',
    200: '#F9B3BB',
    300: '#F36676',
    600: '#EB001B',
    700: '#BC0016',
    800: '#8D0010',
    950: 'yellow', // TODO: Add value from theme.
  },
  warning: {
    50: '#FCF7EE',
    200: '#F3E0BA',
    300: '#ECD197',
    600: '#E0B252',
    700: '#B59042',
    800: '#8A6D32',
    950: 'yellow', // TODO: Add value from theme.
  },
  info: {
    50: '#EDF1FF',
    200: '#B9C8FF',
    300: '#95ADFE',
    600: '#4F76FE',
    700: '#3F60D3',
    800: '#2F4AA7',
  },
  neutral: {
    50: '#FAFAFA',
    100: '#EFEFEF',
    200: '#E5E5E5',
    300: '#D4D4D4',
    400: '#A3A3A3',
    500: '#737373',
    600: '#6B6B6B',
    700: '#535353',
    800: '#343434',
    900: 'yellow', // TODO: Add value from theme.
    950: '#0A0A0A',
  },
};

export { colors };
