import { Button } from '@tectonic/elemason';
import { Text, useStyleConfigV2 } from '@tectonic/elemason-components';
import clsx from 'clsx';

import type { FC } from 'react';
import type { PhoneNoPreviewProps } from './AuthPhoneNoOtpSignInForm.types';

const PhoneNoPreview: FC<PhoneNoPreviewProps> = ({
  data,
  onEdit,
  phoneNumber,
  config,
}) => {
  const [containerStyle, containerClassName] = useStyleConfigV2(
    config?.container ?? {}
  );

  return (
    <div style={containerStyle} className={clsx('flex', containerClassName)}>
      <Text
        data={data.message}
        config={config?.message?.text}
        placeholders={{
          code: phoneNumber.country.stdCodes[0],
          phone: phoneNumber.phone,
        }}
      />

      <Button
        onClick={onEdit}
        data={data.editCta.button}
        config={config?.editCta?.button}
      />
    </div>
  );
};

PhoneNoPreview.displayName = 'PhoneNoPreview';

export { PhoneNoPreview };
