import { createPath, useLocation } from '@remix-run/react';
import {
  AnalyticsEventNames,
  timeEvent,
  trackEvent,
} from '@tectonic/analytics';
import { usePrevious } from '@tectonic/utils';
import { useEffect } from 'react';

import type { Location } from '@remix-run/react';

// Track the page duration along with scroll depth.
const useSessionDurationTracker = () => {
  const location = useLocation();
  const initialLocationRef = usePrevious<Location>(location);
  useEffect(() => {
    timeEvent(AnalyticsEventNames.SESSION_DURATION);
    const landingUrl = createPath(initialLocationRef.current ?? {});

    window.addEventListener('pagehide', () => {
      try {
        const payload = { landingUrl };
        trackEvent(AnalyticsEventNames.SESSION_DURATION, payload, {
          send_immediately: false,
          transport: 'sendBeacon',
        });
      } catch (error) {
        //
      }
    });
  }, [initialLocationRef]);
};

export default useSessionDurationTracker;
