import { useElemasonContext, useHaloScript } from '@tectonic/elemason';
import { HaloScript } from '@tectonic/types';
import { isEmpty } from 'lodash-es';

const useExperiment = ({
  ignoreExperiment,
  expVariant,
}: {
  expVariant?: HaloScript<{ root?: string; page?: string }>;
  ignoreExperiment?: HaloScript<boolean>;
}) => {
  const ignore = useHaloScript<boolean>(ignoreExperiment);
  const variants = useHaloScript<{ root?: string; page?: string }>(expVariant);

  const { experiments } = useElemasonContext();

  // console.log("edd widget variants", expVariant, experiments, variants)

  if (ignore || isEmpty(experiments) || isEmpty(variants)) {
    // default behavior is to render
    return { shouldRender: true };
  }

  const { athenaRootVariantInfo } = experiments;

  if (athenaRootVariantInfo?.expVariant && variants.root) {
    return {
      shouldRender: athenaRootVariantInfo.expVariant === variants.root,
    };
  }
  // TODO: add condition for page variant when needed.

  // default behavior is to render
  return { shouldRender: true };
};

export { useExperiment };
