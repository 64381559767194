import {
  useLocation,
  useParams as useRouteParams,
  useSearchParams,
} from '@remix-run/react';
import { useMemo } from 'react';

const useParams = (): Record<string, any> => {
  const routeParams = useRouteParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  return useMemo(
    () => ({
      pathname: location.pathname,
      routeParams,
      searchParams: Object.fromEntries(searchParams.entries()),
    }),
    [routeParams, searchParams]
  );
};

useParams.displayName = 'useParams';

export { useParams };
