import { useNavigation } from '@remix-run/react';
import { Progress, ProgressIndicator } from '@tectonic/uikit';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { Fetcher } from '@remix-run/react';
import type { FC } from 'react';
import type { TransitionProps } from './Transition.types';

const isLoading = (state: Fetcher['state']): boolean => state === 'loading';

const isIdle = (state: Fetcher['state']): boolean => state === 'idle';

const FAKE_MAX_PROGRESS = 95;
const FRAME_DELAY = 100;

const useProgress = () => {
  const [progress, setProgress] = useState(0);
  const animationRef = useRef<number>();
  const { state: navState } = useNavigation();
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const animate = useCallback(() => {
    animationRef.current = window.requestAnimationFrame(() => {
      setProgress((prevProgress) => {
        // Don't do anything if progress is already at max.
        if (prevProgress === 100) {
          return prevProgress;
        }
        let newProgress = prevProgress + 2 ** (prevProgress / 10);
        // Ensure progress doesn't exceed max progress
        newProgress = Math.min(newProgress, FAKE_MAX_PROGRESS);
        timerRef.current = setTimeout(animate, FRAME_DELAY);
        return newProgress;
      });
    });
  }, [animationRef, setProgress]);

  const start = useCallback(() => {
    setProgress(0);
    animate();
  }, [setProgress, animate]);

  const end = useCallback(() => {
    cancelAnimationFrame(animationRef.current!);
    clearTimeout(timerRef.current);
    setProgress(100);
  }, [setProgress]);

  useEffect(() => {
    if (isIdle(navState)) {
      end();
    } else if (isLoading(navState)) {
      start();
    }
  }, [start, end, navState]);

  return { progress } as const;
};

const Transition: FC<TransitionProps> = () => {
  const { progress } = useProgress();
  const indicatorStyle = { width: `${progress}%` };

  const isVisible = progress !== 0 && progress !== 100;

  return (
    <Progress
      value={progress}
      className={clsx(
        !isVisible && 'hidden',
        'h-1 bg-gray-600',
        'overflow-hidden',
        'fixed left-0 right-0 top-0 z-50'
      )}
    >
      <ProgressIndicator
        style={indicatorStyle}
        className={clsx('h-full bg-blue-500', 'duration-300 ease-in-out')}
      />
    </Progress>
  );
};

Transition.displayName = 'LoadersTransition';

export default Transition;
