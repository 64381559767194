import { LocalStorage } from '@tectonic/storage';
import { isEmpty } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import type { FC } from 'react';
import type { FingerprintsDisplayProps } from './Fingerprints.types';

const getFps = () =>
  [
    LocalStorage.getItem('ttClientFpV0'),
    (LocalStorage.getItem('fingerPrintV3') ?? ({} as any)).visitorId,
    (LocalStorage.getItem('fingerPrintPro') ?? ({} as any)).visitorId,
  ].filter((fp) => !isEmpty(fp));

const FingerprintsDisplay: FC<FingerprintsDisplayProps> = () => {
  const [fingerprints, setFingerprints] = useState<any[]>([]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!params.has('ttfp')) {
      return;
    }
    const fps = getFps();
    if (!isEmpty(fps)) {
      setFingerprints(fps);
    } else {
      setTimeout(() => {
        setFingerprints(getFps());
      }, 1500);
    }
  }, []);

  const fpComp = useMemo(() => {
    if (isEmpty(fingerprints)) {
      return null;
    }

    return (
      <div
        id="ttPandoraFingerprintDebugCont"
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          padding: '2px 10px',
          border: '1px solid #7f7f7f21',
          width: '100%',
          backgroundColor: '#24242be0',
          margin: '0 auto',
          color: '#bebeca',
          textAlign: 'center',
          overflow: 'hidden',
          boxShadow: '0px 10px 10px grey',
          zIndex: '9999',
        }}
        className="flex flex-col"
      >
        <button
          type="button"
          style={{
            float: 'right',
            position: 'absolute',
            top: '0px',
            right: '2px',
            padding: '5px',
            color: 'whitesmoke',
            fontSize: 'large',
          }}
          onClick={() => setFingerprints([])}
        >
          &#x2716;
        </button>
        Fingerprints <br />
        {
          // eslint-disable-next-line react/no-array-index-key
          fingerprints &&
            fingerprints.map((fp, _) => <div key={`fp-${fp}`}>{fp}</div>)
        }
      </div>
    );
  }, [setFingerprints, fingerprints]);

  return fpComp;
};

FingerprintsDisplay.displayName = 'FingerprintsDisplayComponent';

export default FingerprintsDisplay;
