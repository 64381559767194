import { ElemasonWidgetType } from '@tectonic/types';
import { AuthPhoneNoOtpSignInFormWidget } from './AuthPhoneNoOtpSignInForm';
import { AuthProfileUpdateWidget } from './AuthProfileUpdate';
import { CheckoutWidget } from './Checkout';
import { CheckoutRedirectWidget } from './CheckoutRedirect';
import { OrdersWidget } from './Orders';
import { EddWidget } from './Serviceability';

const widgets: Record<string, any> = {
  EDD: EddWidget,
  CHECKOUT_REDIRECT: CheckoutRedirectWidget,
  [ElemasonWidgetType.Orders]: OrdersWidget,
  MAGIC_X_CHECKOUT: CheckoutWidget,
  [ElemasonWidgetType.AuthPhoneNoOtpSignInForm]: AuthPhoneNoOtpSignInFormWidget,
  AUTH_PROFILE_UPDATE: AuthProfileUpdateWidget,
};

export { widgets };
