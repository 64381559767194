import { createContext } from '@tectonic/utils';

interface RootContextType {
  isMobile: boolean;
  currentUser: unknown; // TODO: fix type
  env: Record<string, any>;
}

const errorMessage =
  'useRootContext must be inside a RootProvider with a value';

const name = 'RootContext';

const [RootProvider, useRootContext, RootContext] =
  createContext<RootContextType>({ errorMessage, name });

export { RootContext, RootProvider, useRootContext };
