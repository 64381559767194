import { useLocation, useParams } from '@remix-run/react';
import {
  AnalyticsEventNames,
  timeEvent,
  trackEvent,
} from '@tectonic/analytics';
import {
  getScrollHeight,
  percent,
  useOnWindowScroll,
  usePrevious,
} from '@tectonic/utils';
import { useCallback, useEffect, useRef } from 'react';

const getScrollPercentage = () => {
  // https://css-tricks.com/how-i-put-the-scroll-percentage-in-the-browser-title-bar/
  const scrollTop = window.scrollY;
  const scrollHeight = getScrollHeight();
  const winHeight = window.innerHeight;
  return percent(scrollTop, scrollHeight - winHeight);
};

// Track the page duration along with scroll depth.
const usePageDurationTracker = () => {
  const scrollDepthRef = useRef<number>(0);

  const location = useLocation();
  const params = useParams();
  const paramsRef = usePrevious(params);

  const onScroll = useCallback(() => {
    const sPercent = getScrollPercentage();
    scrollDepthRef.current = Math.max(sPercent, scrollDepthRef.current);
  }, [scrollDepthRef]);

  useOnWindowScroll(onScroll);

  useEffect(() => {
    timeEvent(AnalyticsEventNames.PAGE_DURATION);
    const pageParams = paramsRef.current;
    return () => {
      const payload = {
        pageParams,
        scrollDepth: scrollDepthRef.current,
      };
      trackEvent(AnalyticsEventNames.PAGE_DURATION, payload);
      scrollDepthRef.current = 0;
    };
  }, [paramsRef, location, scrollDepthRef]);
};

export { usePageDurationTracker };
