/* eslint-disable no-underscore-dangle */
import { useSearchParams } from '@remix-run/react';
import { LocalStorage } from '@tectonic/storage';
import { useEffect } from 'react';
import { useAnalyticsEffect } from './useAnalyticsEffect';
import useFirebaseEffect from './useFirebaseEffect';

import type { SessionInfo } from '@tectonic/types';

const useDebugHelpers = (
  { currentUser, sessionInfo }:
    { currentUser: unknown, sessionInfo: SessionInfo }
) => {
  globalThis.___tt___ = globalThis.___tt___ ?? {};
  globalThis.___tt___.currentUser = currentUser;
  globalThis.___tt___.sessionInfo = sessionInfo;
  const [searchParams] = useSearchParams();
  const isTester = searchParams.has('ttTester');
  useEffect(() => {
    if (!isTester) {
      return;
    }
    const pValue = LocalStorage.getItem('isTester');
    if (pValue === null) {
      LocalStorage.setItem('isTester', true);
    }
  }, [isTester]);
};

const injectEnvs = ({ env }: { env: Record<string, string> }) => {
  globalThis.env = env as Record<string, string>;
};

const useAppLaunchEffect = (context: {
  env: Record<string, any>;
  currentUser: unknown;
  isMobile: boolean;
  serverTime: number;
  sessionInfo: SessionInfo;
  ttTester: boolean;
}) => {
  injectEnvs(context);
  useDebugHelpers(context);
  // TODO: Remove firebase. We don't need it.
  useFirebaseEffect(context);
  useAnalyticsEffect({
    env: context.env,
    currentUser: context.currentUser,
    sessionInfo: context.sessionInfo,
    ttTester: context.ttTester,
  });
};

export default useAppLaunchEffect;
