import { useLocation, useParams } from '@remix-run/react';
import { toAnalyticsEventPayload, trackPageView } from '@tectonic/analytics';
import { usePrevious } from '@tectonic/utils';
import { useEffect } from 'react';

import type { Location } from '@remix-run/react';
import type { Nil } from '@tectonic/types';

const usePageViewTracker = () => {
  const location = useLocation();
  const params = useParams();
  const paramsRef = usePrevious(params);

  // There's no way to get previous url reliable. We use ref to remember previous
  // location and construct it manually when it is available.
  // https://stackoverflow.com/questions/3528324/how-to-get-the-previous-url-in-javascript
  const prevLocationRef = usePrevious<Nil<Location>>(null);

  useEffect(() => {
    paramsRef.current = params;
  }, [params, paramsRef]);

  useEffect(() => {
    const payload = toAnalyticsEventPayload({ pageParams: paramsRef.current });
    trackPageView(payload);
    prevLocationRef.current = location;
  }, [location, prevLocationRef, paramsRef]);
};

export { usePageViewTracker };
