{
  "home": {
    "path": "/"
  },
  "pdp": {
    "path": "/products/{slug}"
  },
  "collection-pdp": {
    "path": "/collections/{collectionSlug}/products/{slug}"
  },
  "plp": {
    "path": "/pages/searchtap-search"
  },
  "collection": {
    "path": "/collections/{slug}"
  },
  "inspiration": {
    "path": "/collections/inspiration"
  },
  "inspiration-pdp": {
    "path": "/collections/inspiration/products/{slug}"
  },
  "collection-fbv": {
    "path": "/collections/{slug}/FBV"
  },
  "product-reviews": {
    "path": "/products/{slug}/reviews"
  },
  "checkout": {
    "path": "/checkout"
  },
  "login": {
    "path": "/account/login"
  },
  "account": {
    "path": "/account"
  },
  "profile": {
    "path": "/account/profile"
  },
  "orders": {
    "path": "/orders"
  },
  "order-details": {
    "path": "/orders/{orderId}/{variantId}"
  },
  "account-addresses": {
    "path": "/account/addresses"
  },
  "shipping-details-page": {
    "path": "/pages/shipping-details-page"
  },
  "lookbook": {
    "path": "/pages/lookbook"
  },
  "search-page": {
    "path": "/search"
  },
  "cart-page": {
    "path": "/cart"
  },
  "looks": {
    "path": "/looks"
  },
  "blog": {
    "path": "/blogs/{slug}"
  },
  "article": {
    "path": "/blogs/{slug}/{articleSlug}"
  },
  "article-tag": {
    "path": "/blogs/{slug}/tagged/{tagSlug}"
  },
  "mummy-approved": {
    "path": "/pages/mummy-approved"
  },
  "avada-sitemap": {
    "path": "/pages/avada-sitemap"
  },
  "wishlist": {
    "path": "/account/wishlist"
  },
  "wallet": {
    "path": "/pages/wallet"
  },
  "british-vouge": {
    "path": "/pages/british-vouge"
  },
  "royal-indian": {
    "path": "/pages/royal-indian"
  },
  "desi-indian": {
    "path": "/pages/desi-indian"
  },
  "boho": {
    "path": "/pages/boho"
  },
  "mini-theme-store": {
    "path": "/pages/mini-theme-store"
  },
  "british-vogue-theme-store": {
    "path": "/pages/british-vogue-theme-store"
  },
  "royal-indian-theme-store": {
    "path": "/pages/royal-indian-theme-store"
  },
  "boho-theme-store": {
    "path": "/pages/boho-theme-store"
  },
  "desi-indian-theme-store": {
    "path": "/pages/desi-indian-theme-store"
  },
  "looks-wed-bazaar": {
    "path": "/looks-wed-bazaar"
  },
  "contact": {
    "path": "/pages/contact"
  },
  "bulk-order": {
    "path": "/pages/bulk-order"
  },
  "home-video-looks": {
    "path": "/home-video-looks"
  },
  "terms-and-conditions-for-free-air-fryer": {
    "path": "/pages/terms-and-conditions-for-free-air-fryer"
  }
}
